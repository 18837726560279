import '../styles/index.scss';
import 'bootstrap';
import $ from 'jquery';

(function(){
  emailjs.init("user_qJ0zuc2mjEVewkzJpCnW7");
})();

window.onload = function() {
  document.getElementById('contact-form').addEventListener('submit', function(event) {
    event.preventDefault();
    emailjs.sendForm('living_stratus', 'living_stratus_email', this);
    $('#contact-form').css('display', 'none');
    $('#thank-you').css('display', 'block');

    setTimeout(function(){
      $('#contactModal').modal('hide');
    }, 2000);
  });
};